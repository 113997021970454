<template>
  <b-nav-item-dropdown
    ref="dropdown"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        @click="readNotification"
        :badge="count"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <b-badge pill variant="light-primary"> {{ count }} New </b-badge>
      </div>
    </li>
    <!-- <li class="notification-area mt-1">
      <notification
        @notification="notification"
        :notificationData="notifications"
      />
    </li> -->
    <li class="notification-area mt-1">
      <notification-component @total="getTotal" type="dropdown" />
    </li>
    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-link
        @click="onClick"
        class="btn btn-success d-block"
        to="/notifications"
        >Show / View All Notification</b-link
      >
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BFormCheckbox,
  BLink,
  BMedia,
  BNavItemDropdown,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import NotificationComponent from "@/views/notification/components/NotificationComponent.vue";
export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    BButton,
    BFormCheckbox,
    BLink,
    VuePerfectScrollbar,
    NotificationComponent,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      count: null,
      notifications: [],
    };
  },
  methods: {
    onClick() {
      // Close the menu and (by passing true) return focus to the toggle button
      this.$refs.dropdown.hide(true);
    },
    readNotification(item) {
      this.$store
        .dispatch("user/readNotification", { id: item.id, is_read: 1 })
        .then((response) => {
          if (response.status_code == 200) {
            this.notification();
          }
        })
        .catch((error) => {});
    },
    getTotal(value) {
      this.count = value;
    },
  },
  directives: {
    Ripple,
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    return {
      perfectScrollbarSettings,
    };
  },
};
</script>

<style>
.notification-area {
  height: 300px !important;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
}
</style>
