<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <!-- Bookmarks Container -->
      <!-- remove all underscore from string -->

      <span class="page-title">{{
        $route.name ? $route.name.replace(/[\/-]+/g, " ") : ""
      }}</span>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <p class="m-0 p-0">{{ currentDate() }}</p>
      <dark-Toggler class="d-none d-lg-block" />
      <!-- <search-bar /> -->
      <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from "bootstrap-vue";
import DarkToggler from "./components/DarkToggler.vue";
import Locale from "./components/Locale.vue";
import NotificationDropdown from "./components/NotificationDropdown.vue";
import SearchBar from "./components/SearchBar.vue";
import UserDropdown from "./components/UserDropdown.vue";

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Locale,
    SearchBar,
    DarkToggler,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    currentDate() {
      const current = new Date();
      const date = `${current.getDate()}/${
        current.getMonth() + 1
      }/${current.getFullYear()}`;
      return date;
    },
  },
};
</script>
<style scoped>
.page-title {
  font-weight: 700;
  font-size: 18px;
  line-height: 43px;
  background: #7190ef;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-transform: capitalize;
}
</style>
