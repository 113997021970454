export default [
  {
    title: "Dashboard",
    icon: "HomeIcon",
    // tagVariant: "light-warning",
    route: "dashboard",
    action: "show menu",
    resource: "Dashboard",
  },
  {
    title: "Companies",
    icon: "CommandIcon",
    action: "list",
    resource: "Company",
    children: [
      {
        title: "Company List",
        route: "companies",
        action: "list",
        resource: "Company",
      },
      // {
      //   title: "Company Create",
      //   route: "company-create",
      //   action: "create",
      //   resource: "Company",
      // },
      // {
      //   title: "User List",
      //   route: "user-list",
      //   action: "list",
      //   resource: "Company",
      // },
    ],
  },
  {
    title: "Clients",
    icon: "UserIcon",
    action: "list",
    resource: "Client",
    children: [
      {
        title: "Client List",
        route: "Client List",
        action: "list",
        resource: "Client",
      },
      // {
      //   title: "Add Client ",
      //   route: "customer/create",
      //   action: "create",
      //   resource: "Client",
      // },
    ],
  },
  {
    title: "Employees",
    icon: "UsersIcon",
    action: "list",
    resource: "Employee",
    children: [
      {
        title: "Employee List",
        route: "employees",
        action: "list",
        resource: "Employee",
      },
      // {
      //   title: "Employee Create",
      //   route: "employee-create",
      //   action: "create",
      //   resource: "Employee",
      // },
    ],
  },
  {
    title: "Roster",
    icon: "ApertureIcon",
    action: "create",
    resource: "Roster",
    children: [
      {
        title: "Create Roster",
        route: "create-roster",
        action: "create",
        resource: "Roster",
      },
      
      {
        title: "Published Roster",
        route: "published-roster",
        action: "view published roster",
        resource: "Roster",
      },
    ],
  },
  {
    title: "Timesheet",
    icon: "ClockIcon",
    action: "list",
    resource: "Timesheet",
    children: [
      {
        title: "All Timesheets",
        route: "time-sheets",
        resource: "Timesheet",
        action: "list",
      },
    ],
  },
  {
    title: "Job Post",
    icon: "BriefcaseIcon",
    action: "list",
    resource: "Job Post",
    children: [
      {
        title: "Open Jobs",
        route: "job-post",
        resource: "Job Post",
        action: "list",
      },
      {
        title: "Drafts",
        route: "job-post-draft",
        resource: "Job Post",
        action: "draft list",
      },
      {
        title: "Archived Jobs",
        route: "job-post-history",
        resource: "Job Post",
        action: "job history",
      },
      {
        title: "Job Templates",
        route: "view-job-templete",
        resource: "Job Post",
        // this action is not available
        // action: "templete list",
        action: "template list",
      },
      {
        title: "Applied Jobs",
        route: "job-post-applied-jobs",
        resource: "Job Post",
        action: "view applied jobs",
      },
    ],
  },

  {
    title: "Settings",
    icon: "SettingsIcon",
    action: "show menu",
    resource: "Settings",
    children: [
      {
        title: "Super Admin",
        route: "Super Admin List",
        action: "list",
        resource: "Super Admin",
      },
      // {
      //   title: "Bank Account Types",
      //   route: "account-types",
      //   resource: "Bank Account Type",
      //   action: "list",
      // },
      // {
      //   title: "Banks",
      //   route: "banks",
      //   resource: "Bank",
      //   action: "list",
      // },
      {
        title: "Categories",
        route: "categories",
        resource: "Category",
        action: "list",
      },
      {
        title: "Client Location Config",
        route: "client-location-break-config",
        resource: "Client",
        action: "location break configuration",
      },
      // {
      //   title: "Countries",
      //   route: "countries",
      //   resource: "Country",
      //   action: "list",
      // },
      // {
      //   title: "Designations",
      //   route: "designations",
      //   resource: "Designation",
      //   action: "list",
      // },

      // this is actually Duty
      {
        title: "Job Roles",
        route: "job-roles",
        resource: "Job Role",
        action: "list",
      },
      // {
      //   title: "Employment Types",
      //   route: "employment-types",
      //   resource: "Employment Type",
      //   action: "list",
      // },
      // {
      //   title: "Fields",
      //   route: "fields",
      //   resource: "Fields",
      //   action: "list",
      // },
      {
        title: "Industries",
        route: "industries",
        resource: "Industry",
        action: "list",
      },
      {
        title: "Locations",
        route: "locations",
        resource: "Location",
        action: "list",
      },
      {
        title: "Global Configuration",
        route: "global-config",
        resource: "Global Configuration",
        action: "view",
      },

      {
        title: "Sub Locations",
        route: "sub-locations",
        resource: "Sub Location",
        action: "list",
      },
      {
        title: "Positions",
        route: "positions",
        resource: "Position",
        action: "list",
      },
      // {
      //   title: "Sectors",
      //   route: "sectors",
      //   resource: "Sector",
      //   action: "list",
      // },
      {
        title: "Skills",
        route: "skills",
        resource: "Skill",
        action: "list",
      },
      {
        title: "Shift Config",
        route: "shifts",
        resource: "Shift Configuration",
        action: "view",
      },

      {
        title: "Role & Permission",
        route: "roles",
        resource: "Role Permission",
        action: "list",
      },
      
    ],
  },
];
